@import url(https://fonts.googleapis.com/css2?family=Outfit:wght@300;400;600&display=swap|Inter:wght@500;600&display=swap);

@font-face {
  font-family:'Cera Pro';
  font-weight:400;
  src:url(/static/media/CeraPro.76103000.ttf);
}
@font-face {
  font-family:'Cera Pro';
  font-weight:500;
  src:url(/static/media/CeraProMedium.2bc02ae8.otf);
}
@font-face {
  font-family:'Cera Pro';
  font-weight:600;
  src:url(/static/media/CeraProBold.23dd9a3e.otf);
}

* {
  margin:0;
  padding:0;
  font-family:'Cera Pro', sans-serif;
}

/* CUSTOM SCROLLBAR */
::-webkit-scrollbar {
  /* Width*/
  width: 5px;
}
::-webkit-scrollbar-track {
  /* Track */
  background: rgba(0, 0, 0, 0.008);
}
::-webkit-scrollbar-thumb {
  /* Handle */
  background: rgba(136, 136, 136, 0.1);
}

:root {
  --pfp-height: 100px;
  --bgp-height: 150px;

  --socials-icon-size: 35px;

  --min-height: 50px;
}

h4,
h5 {
  font-weight:500;
  padding-bottom:10px;
}

body {
  background:#fefefe;
}

header {
  text-align:center;
}

.lightscamera {
  text-align:center;
}

.demo-container {
  display:flex;

  width:800px;
  margin:50px auto;
  justify-content:space-between;
  align-items:flex-start;
  text-align:center;
}

.editor .demo-container {
  width:100%;
  margin:0;
}

.demo {
  width:350px;
  height:600px;
  border-radius:6px;
  overflow:hidden;
  box-shadow: 1px 0 10px rgba(0,0,0,0.03);
}

.mock-background {
  flex-grow:1;
  padding:3vw calc(3vw - 30px) 3vw 3vw;
  min-height:calc(100vh - 6vw);
  max-height:calc(100vh - 6vw);
  display:flex;
  align-items:stretch;
  justify-content: stretch;
}

.profile-container-wrapper {
  max-height:100%;
  overflow:auto;
  border-radius:20px;
  width:100%;
  background:white;
  box-shadow: 1px 0 10px rgba(0,0,0,0.03);
  box-sizing: border-box;
  padding:3vw;
  display:flex;
  align-items:flex-start;
  
  object-fit:cover;
}

.prod {
  box-shadow:none;
  border-radius:0;
  height:100vh;
}

.profile-container-wrapper.left {
  justify-content:flex-start;
} .profile-container-wrapper.centre {
  justify-content:center;
} .profile-container-wrapper.right {
  justify-content:flex-end;
}

.profile-container {
  text-align:center;
  width:350px;
  border-radius:6px;
  -webkit-user-select:none;
      -ms-user-select:none;
          user-select:none;
}

.left .profile-container {
  text-align:left;
} .right .profile-container {
  text-align:right;
}

.demo-container .profile-container {
  margin-top:0;
  margin-bottom:0;
  height:auto;
}

.profile {
  width:100%;
}

.profile .photos {
  height:150px;
  height:var(--bgp-height);
  margin-bottom:calc(100px / 2);
  margin-bottom:calc(var(--pfp-height) / 2);
}

.profile .photos .bgp {
  width:100%;
  height:100%;
  border-radius:4px 4px 0 0;
  object-fit:cover !important;
}

.profile .photos .pfp {
  width:100px;
  width:var(--pfp-height);
  height:100px;
  height:var(--pfp-height);
  margin:calc(calc(100px * -1) / 2) auto 0 auto;
  margin:calc(calc(var(--pfp-height) * -1) / 2) auto 0 auto;
  box-sizing: border-box;

  object-fit:cover;

  border-radius:100%;
}

.left .profile .photos .pfp {
  margin-left:20px;
} .right .profile .photos .pfp {
  margin-right:20px;
}

.profile-container .info h3 {
  padding-bottom:10px;
}

.profile-container.pf-alone .info h3 {
  padding:5px 0 7px 0;
}

.profile-container .info p {
  padding-bottom:10px;
}

.profile-container.none,
.profile-container.pf-alone {
  box-shadow:none;
}

.profile-container.pf-alone .profile .info {
  padding-top:0;
}

.profile-container.bg-alone .info h3 {
  padding:0;
}

.profile-container.none .profile .photos {
  display:none;
}
.profile-container.bg-alone .profile .photos {
  margin-bottom:15px;
}
.profile-container.bg-alone .profile .photos .pfp {
  display:none;
}
.profile-container.pf-alone .profile .photos {
  height:auto;
  margin-bottom:10px;
}
.profile-container.pf-alone .profile .photos .bgp {
  display:none;
}
.profile-container.pf-alone .profile .photos .pfp {
  margin-top:0;
}

.profile .info {
  padding:10px 20px 20px 20px;
}

.profile .socials {
  display:flex;
  width:100%;
  justify-content: center;
  box-sizing: border-box;
  padding:0 20px 10px 20px;
  flex-wrap:wrap;
  grid-gap:15px;
  gap:15px;
}

.left .profile .socials {
  justify-content:flex-start;
} .right .profile .socials {
  justify-content:flex-end;
}

.profile .socials .icon {
  height:35px;
  height:var(--socials-icon-size);
  min-height:35px;
  min-height:var(--socials-icon-size);
  max-height:35px;
  max-height:var(--socials-icon-size);
  width:35px;
  width:var(--socials-icon-size);
  min-width:35px;
  min-width:var(--socials-icon-size);
  max-width:35px;
  max-width:var(--socials-icon-size);

  border-radius:100%;
  background:white;
  display:flex;
  align-items:center;
  justify-content:center;
  box-sizing: border-box;
}

.profile .socials .icon.facebook {
  background:#1B76F2;
} .profile .socials .icon.linkedin {
  background: #0A66C2;
} .profile .socials .icon.instagram {
  background:linear-gradient(to top right, #FEDA75, #FA7E1E, #D62976, #962FBF, #4F5BD5)
} .profile .socials .icon.twitter {
  background:#1DA1F2;
} .profile .socials .icon.twitch {
  background:#9146FF;
} .profile .socials .icon.youtube {
  background:#FF0000;
} .profile .socials .icon.github {
  background:#24292E;
} .profile .socials .icon.spotify {
  background:#1DB954;
} .profile .socials .icon.snapchat {
  background:#FFFC00;
}

.profile .socials .icon img {
  height:20px;
  transition:0.2s all cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.profile .socials .icon:hover > img {
  opacity:0.8;
}

.profile .links {
  padding:10px 20px 20px 20px;
  display:flex;
  flex-direction:column;
  box-sizing: border-box;
}

.profile .links .link-item {
  width:100%;
  display:flex;
  align-items:center;
  margin-top: 15px;
  border-radius:4px;
  box-sizing: border-box;
  overflow:hidden;
  justify-content: flex-start;

  min-height:50px;

  min-height:var(--min-height);
  text-decoration:none;
  color:inherit;
  transition:0.2s opacity cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.profile .links .link-item:hover {
  opacity:0.8;
}

.profile .link-item .link-img {
  min-height:50px;
  min-height:var(--min-height);
  width:50px;
  background:grey;
}

.profile .link-item .link-content {
  padding:15px;
  display:flex;
  flex-direction:column;
  align-items:flex-start;
  text-align:left;
  box-sizing: border-box;
}

.profile .link-item .link-content .link-text {
  font-weight:500;
}

.profile .link-item .link-content .link-url {
  font-size: 15px;
  max-width:250px;
  white-space:nowrap;
  overflow:hidden;
  text-align:left;
  text-overflow:ellipsis;
  opacity:0.5;
}

.engage {
  text-align:left;
  flex-grow:1;
  margin-left:50px;
  background:#fff;
  border-radius:10px;
  box-shadow: 0 0 10px rgba(0,0,0,0.03);
}


.engage .add-website {
  margin-bottom:15px;
}

.engage .input-section {
  box-sizing: border-box;
  padding:25px;
  border-bottom:1px solid #eee;
}

.engage .input-section p {
  padding-bottom:10px;
  padding-top:5px;
}

.engage .input-section:last-child {
  border-bottom:none;
}

.engage .input-row {
  display:flex;
  justify-content:space-between;
  padding-bottom:15px;
  align-items:center;
  grid-column-gap:10px;
  -webkit-column-gap:10px;
          column-gap:10px;
}

.engage .input-row .input-box,
.engage .input-row .website-name,
.engage .input-row .website-link {
  width:calc(50% - 10px);
}

.image-row {
  padding-bottom:15px;
}

.image-row .current-image {
  display:flex;
  justify-content:space-between;
  align-items:center;
  grid-column-gap:10px;
  -webkit-column-gap:10px;
          column-gap:10px;
  width:100%;
}

.image-row .dl-link {
  width: 350px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor:pointer;
  position:relative;
  color:black;
  text-decoration: none;
  transition: 0.2s all cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.image-row .dl-link:hover {
  opacity:0.6;
}

/* .image-row .dl-link:hover::after {
  width:100%;
  left:0;
  content: "copy";
  position: absolute;
  text-align:center;
  background:rgba(255,255,255,0.7);
  backdrop-filter: blur(1px);
} */

.input-row .item-actions {
  display:flex;
  align-items:center;
  grid-column-gap:10px;
  -webkit-column-gap:10px;
          column-gap:10px
}

.input-row .delete-item {
  cursor:pointer;
  padding:10px;
  background:rgba(0,0,0,0.02);
  border-radius:100%;
  display:flex;
  align-items:center;
  justify-content:center;
  transition:0.2s all cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.input-row .delete-item:hover {
  background:rgba(0,0,0,0.04);
  box-shadow:0 0 10px rgba(0,0,0,0.02);
}

.input-row .move-items {
  display:flex;
  flex-direction:column;
  justify-content:space-between;
}

.input-row .move-up,
.input-row .move-down {
  cursor:pointer;
  display:flex;
  align-items:center;
  justify-content:center;
}

.input-row .move-up:hover,
.input-row .move-down:hover {
  opacity:0.5;
}

.input-section .add-item {
  -webkit-user-select:none;
      -ms-user-select:none;
          user-select:none;
  cursor:pointer;
  padding:8px 13px;
  background:#1a73e842;
  display:inline-flex;
  justify-content:space-between;
  align-items:center;
  grid-column-gap:8px;
  -webkit-column-gap:8px;
          column-gap:8px;
  color:#1a73e8;
  border-radius:5px;
  transition:0.2s all cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.input-section .add-item:hover {
  background:#1a73e856;
}

.input-section .slider-container {
  display:flex;
  flex-direction:column;
  position:relative;
  margin-bottom:10px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  line-height:170%;
}

.input-section .slider {
  display:flex;
  justify-content:space-between;
}

.input-section .slider .slide-item {
  cursor:pointer;
  width:100%;   
  text-align:center;
  border-radius:3px;
  padding:5px;
}

.input-section .slider-faux {
  display:flex;
  position:absolute;
  width:100%;
  z-index:-1;
  height:100%;
  box-sizing: border-box;
  
  border-radius:5px;
  background:white;
  border:1px solid #f5f5f5;
}

.input-section .slider-faux .slide-slider {
  width:calc(100% / 3);
  background:#f5f5f5;
  position:absolute;
  height:100%;
  border-radius:5px;
  transition: 0.2s all cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.input-section .slider-faux.left > .slide-slider {
  left:0;
} .input-section .slider-faux.centre > .slide-slider {
  left:calc(50% - calc(calc(100% / 3) / 2));
} .input-section .slider-faux.right > .slide-slider {
  left:calc(100% - calc(100% / 3));
}


.editor .engage {
  overflow:auto;
  flex-grow:0;
  box-shadow:none;
  margin-left:0;
  background:transparent;
  max-height:calc(100vh - 6vw);
  padding:3vw 100px 3vw 0;
}

.editor .engage .profile-section {
  box-sizing: border-box;
  padding:25px;
  border-bottom:1px solid #eee;
}

.engage .more {
  padding:10px;
  cursor:pointer;
  border-radius:100%;
  padding:10px;
  display:inline-flex;
  background:rgba(0,0,0,0.02);
  transition:0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.engage .more:hover {
  background:rgba(0,0,0,0.04);
  box-shadow:0 0 10px rgba(0,0,0,0.02);
}

.engage .more-actions {
  padding-top:20px;
  max-height:40px;
  overflow:hidden;
  transition:0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.engage .more-actions.false {
  max-height:0;
}


input[type="text"] {
  border:1px solid #eee;
  box-sizing: border-box;
  padding:5px 9px;
  border-radius:5px;
  letter-spacing:0.1px;
  font-size:18px;
  transition:0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
  outline:none;
  width:100%;
}

input[type="text"]:hover {
  background:rgb(248, 248, 248);
}

input[type="text"]:focus {
  background:rgb(248, 248, 248);
  border-color:#ccc;
}

input[type="range"] {
  width:100%;
  cursor:pointer;

  -webkit-appearance: none;
  height: 5px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  transition: opacity .2s;
  border-radius:5px;
}

input[type="range"]:hover {
  opacity:0.8;
}

.option-colour {
  display:flex;
  align-items:center;
  text-transform: uppercase;
  font-weight:500;
  grid-gap:10px;
  gap:10px;
}

.option-colour.outline {
  max-height:0;
  transition:0.2s all cubic-bezier(0.25, 0.46, 0.45, 0.94);
  overflow:hidden;
}

.option-colour.true {
  max-height:40px;
}

input[type="color"] {
  cursor:pointer;
  padding:0;
  border:none;
  background:none;
}

input[type="color"]:hover {
  opacity:0.8;
}

input[type="file"] {
  display:none; /* use label to style */
}

.upload-image {
  -webkit-user-select:none;
      -ms-user-select:none;
          user-select:none;
  cursor:pointer;
  padding:8px 13px;
  background:#1a73e842;
  display:inline-flex;
  justify-content:space-between;
  align-items:center;
  grid-column-gap:8px;
  -webkit-column-gap:8px;
          column-gap:8px;
  color:#1a73e8;
  border-radius:5px;
  transition:0.2s all cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.upload-image:hover {
  background:#1a73e856;
}


.option {
  padding-bottom:10px;
}

.input-box.social,
.input-box.websites {
  padding-top:10px;
}

.add-social {
  margin-bottom:15px;
  display:flex;
  align-items:center;
}

.add-social h5 {
  font-size:16px;
  padding-right:10px;
}


/* Toggle Styles */
.toggle-container {
  width:55px;
  height:30px;
  background:#EEE;
  border-radius:20px;
  box-sizing: border-box;
  padding:4px;
  cursor:pointer;
  transition:0.2s all;
}

.toggle-container .toggle-nob {
  height:22px;
  width:22px;
  border-radius:100%;
  background:#777;
}

/* Toggle is set to false */
.toggle-container.false {
  padding-left:4px;
}
.toggle-container.false .toggle-nob { 
  background:red;
}

/* Toggle is set to true */
.toggle-container.true {
  padding-left:calc(55px - 26px);
}
.toggle-container.true .toggle-nob {
  background:green;
}



.sidebar {
  position:fixed;
  width:70px;
  right:0;
  top:0;
  height:100vh;
  display:flex;
  flex-direction:column;
  align-items:center;
  box-sizing:border-box;
  padding:10px;
  justify-content:space-between;

  /* background:#0051bb; */
  border-left:solid;
  border-width:1px;
  border-color:#EEE;
}

.circle-button {
  border-radius:100%;
  background:#0051bb;
  margin:5px;
  height:40px;
  width:40px;
  display:flex;
  justify-content:center;
  align-items:center;
  color:white;
  transition:0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.circle-button:hover {
  background:#004299;
  color:#EEE;
  box-shadow:0 0 10px rgba(0,0,0,0.1);
}

.button,
input[type="submit"] {
  border:none;
  box-sizing: border-box;
  padding:9px 15px;
  border-radius:5px;
  text-decoration:none;
  color:white;
  font-size:15px;
  font-weight:bold;
  letter-spacing:0.1px;
  transition:0.2s cubic-bezier(0.075, 0.82, 0.165, 1);

  cursor:pointer;

  background:#1a73e862;
  color:#0051bb;
}

.button:hover,
input[type="submit"]:hover {
  background:#1a73e896;
}

.button.red {
  background:#e8321a62;
  color:#bb0c00;
} .button.red:hover {
  background:#e8321aa8;
}

.button.green {
  background:#6ce81a4f;
  color:#00bb19;
} .button.green:hover {
  background:#6ce81a7e;
}

.button.google {
  background:white;
  border:1px solid #eee;
  color:black;
  display:flex;
  align-items:center;
  grid-gap:10px;
  gap:10px;
  margin:0 auto;
} .button.google:hover {
  background:#F1F1F1;
}

/* Button disabled styles */

.button:disabled,
.button.google:disabled:hover,
input[type="submit"]:disabled,
input[type="submit"]:disabled:hover {
  opacity:0.5;
  background:white;
}

/* deletepage true TF button content: cancel */
.button.deletepage {
  outline:none;
}
.button.dptrue {
  background:#1a73e862;
  color:#0051bb;
  outline:none;
  border-radius:0 5px 5px 0;
}
.button.dptrue:hover {
  background:#1a73e896;
}

.page-actions .button:disabled,
.page-actions .page-delete-confirm:disabled {
  background:grey;
  color:darkgrey;
}

.delete-container {
  display:flex;
  justify-content:flex-end;
}

.page-delete-confirm {
  border:none;
  box-sizing: border-box;
  padding:9px 15px;
  font-size:15px;
  cursor:pointer;
  font-weight:bold;
  outline:none;
  border-radius:5px 0 0 5px;
  background:#e8321a62;
  color:#bb0c00;
} .page-delete-confirm:hover {
  background:#e8321aa8;
}


/* button tray styles */

.button-tray {
  display:flex;
}
.button-tray .button {
  margin-right:15px;
}
.button-tray .button:last-child {
  margin-right:0;
}

.text-link {
  font-weight:bold;
  text-decoration:none;
  color:#1a73e8;
  display:inline-table;
}

.text-tray {
  padding-top:20px;
  display:flex;
  flex-direction: column;
  align-items:flex-start;
}

.text-tray .text-link {
  margin-bottom:10px;
}
.text-tray .text-link:last-child {
  margin-bottom:0;
}

.text-link.back {
  color:#777;
}

.account-component-back {
  /* Width has to be the same with below for design to make sense */
  width:350px;
  margin-left:auto;
  margin-right:auto;
  text-align:center;
  display:flex;
  justify-content: center;
}

.account-component {
  /* TODO: mobile-optimize */
  width:350px;
  margin:100px auto 20px auto;
  background:white;
  box-sizing: border-box;
  padding:30px;
  border-radius:4px;
  box-shadow:0 0 10px rgba(0,0,0,0.02);
}

.account-component h1 {
  font-size:20px;
}

.account-component h3 {
  font-weight:normal;
  font-size:15px;
  padding-bottom:10px;
}

.account-component .input-group {
  padding-top:20px;
}

.error-alert {
  background:#f4482534;
  margin-top:15px;
  box-sizing: border-box;
  padding:10px;
  border-radius:5px;
  color:#F44725;
  font-weight: bold;
}

.message-alert {
  background:#ffa8112f;
  margin-top:15px;
  box-sizing: border-box;
  padding:10px;
  border-radius:5px;
  color:#FFA611;
  font-weight: bold;
}

.gospel-alert {
  background:#5cff112f;
  box-sizing: border-box;
  padding:20px;
  color:#65c041;
  font-weight: bold;
}


/* home page login trays */

.homepage-error {
  background:#ff11112f;
  margin-top:15px;
  box-sizing: border-box;
  padding:15px;
  border-radius:5px;
  color:#ff1111;
  width:auto;
  display:table;
  margin-right:auto;
  font-weight:bold;
  margin-left:auto;
}


/* Preview Page Styles - Actions (global) */

.actions,
.page-actions {
  width:350px;
  margin: 0 auto 20px auto;
  box-sizing: border-box;
  padding:20px;
  background:#1a73e811;
  box-shadow:0 0 10px rgba(0,0,0,0.02);
  border-radius:4px;
  transition:0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.actions:hover,
.page-actions:hover {
  background:#1a73e81a;
}

.actions h4,
.page-actions h4 {
  padding-bottom:10px;
}

.page-actions {
  margin-bottom:100px;
}



/* Preview Page Styles - Setup */

.setup {
  width:350px;
  margin: 100px auto 30px auto;
  background:white;
  box-shadow:0 0 10px rgba(0,0,0,0.02);
  border-radius:4px;
  overflow:hidden;
}

.setup .message-alert {
  margin:0;
  border-radius:0;
  padding:20px;
}

.setup .error-alert {
  margin:0;
  border-radius:0;
  padding:20px;
}

.preview .account-bar {
  box-sizing: border-box;
  padding:20px;
  background:#0051bb;
  color:white;
}

.setup .setup-inner {
  box-sizing: border-box;
  padding:20px;
}

.setup h2 {
  padding-bottom:20px;
}

.preview h3 {
  padding-top:20px;
  font-size:18px;
}

.setup .setup-input {
  display:flex;
  align-items: center;
  padding:20px 0 15px 0;
}

.setup .setup-input input[type="text"] {
  padding:0;
  border-style:none none solid none;
  border-radius:0;
  font-size:16px;
  background:rgb(245, 245, 245);
}

.setup .setup-input-demo {
  color:#777;
  padding:10px 0 20px 0;
}


/* Preview Page Styles - Preview */

.main {
  width:350px;
  background:white;
  margin:50px auto;
  border-radius:4px;
  box-shadow:0 0 10px rgba(0,0,0,0.02);
  overflow:hidden;
}

.main .main-inner {
  padding:0 20px 20px 20px;
  box-sizing: border-box;
}

.editor {
  background:rgba(0,0,0,0.007);
}

/* EDITOR (its like basically the same as the intro) */

.save-change {
  box-sizing: border-box;
  padding:20px;
  display:flex;
  justify-content:space-between;
  align-items:center;
}

.save-change .save-change-reminder {
  color:#777;
  font-style:italic;
}

a.profile-footer-link {
  font-weight:medium;
  opacity:0.2;
  color:black;
  text-decoration: none;
  margin:0 auto;
  display:table;
  font-size:14px;
  margin-bottom:30px;
}


/* Mobile */

@media only screen and (max-width: 800px) {
  .demo-container {
    width:100%;
    flex-direction: column;
  }

  .demo-container .engage {
    width:350px;
    margin:35px auto 0 auto;
  }

  .inner-container h2 {
    font-size:16px;
  }

  .introduction h1 {
    font-size:32px !important;
  }

  .about h2,
  .lightscamera h2 {
    font-size:28px !important;
  }

  .profile-container {
    margin:35px auto;
    width:100vw;
  }

  .editor {
    padding-top:0;
  }
  
  .editor .engage {
    padding:0;
    max-height:none;
  }

  .mock-background {
    width:100%;
    padding:0;
    max-height:none;
    min-height:0;
  }

  .profile-container-wrapper {
    height:auto;
    max-height:none;
    min-height:0;
    border-radius:0 0 20px 20px;
  }
  
  .prod {
    height:100vh;
    border-radius:0;
  }

  .sidebar {
    position: relative;
    height: auto;
    width:100%;
    flex-direction:row;
    background:#0051BB;
    color:white;
  }

  .sidebar h1 {
    font-weight:normal;
  }

  .sidebar .circle-button {
    background:#00367c;
    color:#FFF;
  }


}
:root {
    --header-height:100px;

    --inner-container-width: 1200px;
}

.inner-container {
    width:1200px;
    width:var(--inner-container-width);
    margin:0 auto;
}

header {
    height:100px;
    height:var(--header-height);
    display:flex;
    align-items:center;
    font-size:15px;
}

.introduction {
    display:flex;
    align-items:center;
    justify-content: center;
    height:calc(100vh - 100px);
    height:calc(100vh - var(--header-height));
    padding-bottom:100px;
    box-sizing: border-box;
    padding-left:6vw;
    padding-right:6vw;
}

.introduction h1 {
    color:rgb(31, 31, 31);
    font-size:70px;
    text-align: center;
}

.about {
    text-align:center;
}

.about h2,
.lightscamera h2 {
    font-size:45px;
    padding-bottom:75px;
}

.lightscamera {
    padding: 100px 0 175px 0;
}
